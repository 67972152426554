// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-projects-idparse-jsx": () => import("./../../../src/projects/idparse.jsx" /* webpackChunkName: "component---src-projects-idparse-jsx" */),
  "component---src-projects-notionclock-jsx": () => import("./../../../src/projects/notionclock.jsx" /* webpackChunkName: "component---src-projects-notionclock-jsx" */),
  "component---src-projects-vonnieclock-jsx": () => import("./../../../src/projects/vonnieclock.jsx" /* webpackChunkName: "component---src-projects-vonnieclock-jsx" */),
  "component---src-templates-redirect-jsx": () => import("./../../../src/templates/Redirect.jsx" /* webpackChunkName: "component---src-templates-redirect-jsx" */),
  "component---src-templates-subpage-jsx": () => import("./../../../src/templates/Subpage.jsx" /* webpackChunkName: "component---src-templates-subpage-jsx" */)
}

